import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import { DateTime } from 'luxon';
import { TextField } from '@material-ui/core';
import { intersection } from 'lodash';

import { MultiDateInput } from 'atom/input';
import { NoCareData } from 'atom/care';
import { Push, Space, HorizontalLayout, VerticalLayout } from 'atom/layout';
import { UnderlinedText, Gray16, Primary16 } from 'atom/text';
import { TimeRangePopup } from 'component/popup';
import {
  getEndTimePlaceholder,
  getMaximumServiceTime,
  getMinimunServiceTime,
  getStartTimePlaceholder,
} from 'constant';
import { useStores } from 'state';
import { CareType, CareTypeNew } from 'model';
import { useTopmost } from 'util/index';
import { Title, Subtitle } from './style';
import { RequestStep6 } from './RequestStep6';
import { RequestStepHeroType } from '.';
import {
  count2DaysLater,
  count3DaysLater,
} from '../../customer/request/RequestStep4';
import moment from 'moment';

const service = {
  [CareTypeNew.PickUp]: '하원 + 놀이돌봄',
  [CareTypeNew.DropOff]: '등원 + 놀이돌봄',
  [CareTypeNew.PlayOnly]: '놀이돌봄만',
};

export const RequestStep5Irregular = observer(({ Bottom, onNext }) => {
  const { customerStore } = useStores();
  const [date, setDate] = useState<DateTime[]>([]);
  const [inputs, setInputs] = useState(
    customerStore.applyForm.careTypes.map(x => ({
      type: x,
      startTime: null,
      endTime: null,
    })),
  );
  const [schedules, setSchedules] = useState(customerStore.applyForm.schedules);
  const isOnlyPlayCare = customerStore.applyForm.careTypes.includes(
    CareTypeNew.PlayOnly,
  );
  // const hasExtraService =
  //   customerStore.form.careTypes.includes(CareType.Cooking) ||
  //   customerStore.form.careTypes.includes(CareType.Cleaning) ||
  //   customerStore.form.careTypes.includes(CareType.Premium);

  const onClickNext = () => {
    if (
      schedules.length >= 2 ||
      schedules.some(x => {
        const end = moment(x.endTime, 'hh:mm');
        const start = moment(x.startTime, 'hh:mm');
        const duration = moment.duration(end.diff(start));
        return duration.asHours() >= 5;
      })
    ) {
      return onNext(RequestStepHeroType);
    }
    return showConfirm(
      '비정기 돌봄은 하루 이용시 최소 5시간,\n또는 2일 이상 이용시 신청 가능합니다.',
    );
  };
  const onClickAdd = () => {
    // console.log(
    //   'c',
    //   schedules.some(
    //     x =>
    //       DateTime.local().plus({ month: 2 }) <
    //       DateTime.fromFormat(x.date, 'yyyy-MM-dd'),
    //   ),
    // );
    if (date.length === 0) {
      showConfirm('날짜를 선택해주세요.');
      return;
    }
    if (inputs.every(x => !x.startTime)) {
      showConfirm('시간을 선택해주세요.');
      return;
    }
    if (date.some(x => DateTime.local().plus({ month: 2 }) < x)) {
      showConfirm('최대 2개월 이내 날짜만 선택 가능해요.');
      setDate([]);
      return;
    }

    if (date.some(x => DateTime.local().plus({ day: count2DaysLater() }) > x)) {
      showConfirm('영업일 기준, 2일 이후부터 신청가능해요.');
      setDate([]);
      return;
    }
    if (
      schedules.some(x =>
        date.map(x => x.toFormat('yyyy-MM-dd')).includes(x.date),
      )
    ) {
      showConfirm('이미 등록된 날짜에요.');
      return;
    }

    inputs
      .filter(x => !!x.startTime)
      .forEach(input => {
        setSchedules(schedules => [
          ...schedules,
          ...date.map(d => ({
            date: d.toFormat('yyyy-MM-dd'),
            ...input,
          })),
        ]);
      });

    setDate([]);
    setInputs(
      customerStore.applyForm.careTypes.map(x => ({
        type: x,
        startTime: null,
        endTime: null,
      })),
    );
  };

  useEffect(() => {
    if (schedules.length > 0) {
      const asc = schedules.sort((a: any, b: any) => {
        return DateTime.fromFormat(a.date, 'yyyy-MM-dd').startOf('day') >=
          DateTime.fromFormat(b.date, 'yyyy-MM-dd').startOf('day')
          ? 1
          : -1;
      });
      customerStore.applyForm.careStartAt = asc[0].date;
      customerStore.applyForm.careEndAt = asc[asc.length - 1].date;
    }
    customerStore.applyForm.schedules = schedules;
    // customerStore.form.careService = schedules
    //   .map(x => `${x.date} / ${x.type} / ${x.startTime}~${x.endTime}`)
    //   .join('\n');
    // console.log(customerStore.form.careService);
  }, [schedules]);

  return (
    <>
      <Title>
        돌봄이 필요한 요일과
        <br />
        시간을 알려주세요.
      </Title>
      <Space height={18} />
      <Subtitle>
        {isOnlyPlayCare
          ? '*놀이돌봄은 최소 3시간 이상부터 가능해요. '
          : '*등원은 최소 2시간, 하원은 최소 3시간 이상부터 가능해요.'}

        {/* {hasExtraService && (
          <>
            <br />
            *청소/요리 서비스의 경우, 아이가 없는 시간에만 이용 가능합니다.
          </>
        )} */}
      </Subtitle>
      <Space height={52} />

      <HorizontalLayout center>
        <Gray16>돌봄 날짜</Gray16>
        <div style={{ flex: 1 }} />
        <MultiDateInput hasIcon value={date} onChange={e => setDate(e)} />
      </HorizontalLayout>
      <Space height={17} />
      <hr />
      <Space height={17} />

      {inputs.map((x, idx) => (
        <React.Fragment key={idx + schedules.length * 10}>
          <CareTimeItem
            type={x.type}
            startTime={x.startTime}
            endTime={x.endTime}
            onChange={(start, end) => {
              inputs[idx].startTime = start;
              inputs[idx].endTime = end;
              setInputs([...inputs]);
            }}
          />
          <Space height={14} />
        </React.Fragment>
      ))}
      <Space height={3} />
      <hr />
      <Space height={26} />

      {schedules.length === 0 && (
        <>
          <NoCareData />
          <Space height={20} />
        </>
      )}
      {schedules.map((x, idx) => (
        <React.Fragment key={idx}>
          <CareScheduleItem
            type={service[x.type]}
            date={x.date}
            startTime={x.startTime}
            endTime={x.endTime}
            onRemove={() => {
              setSchedules(schedules.filter(y => y !== x));
            }}
          />
          <Space height={17} />
        </React.Fragment>
      ))}
      <Space height={10} />

      <UnderlinedText onClick={onClickAdd}>+ 돌봄일 추가하기</UnderlinedText>
      <Space height={62} />
      <Bottom
        progress={(1 / 11) * 5}
        disabled={schedules.length === 0}
        onClickNext={onClickNext}
      />
    </>
  );
});

interface CareScheduleItemProps {
  type: CareTypeNew;
  date: string;
  startTime: string;
  endTime: string;
  onRemove: () => void;
}
const CareScheduleItem = ({
  type,
  date,
  startTime,
  endTime,
  onRemove,
}: CareScheduleItemProps) => {
  return (
    <CareScheduleItemContainer center>
      <VerticalLayout>
        <Primary16 semiBold>{date}</Primary16>
        <Gray16 semiBold>
          {type} / {startTime} ~ {endTime}
        </Gray16>
      </VerticalLayout>
      <Push />
      <CloseIcon onClick={onRemove} />
    </CareScheduleItemContainer>
  );
};

interface CareTimeItemProps {
  type: CareTypeNew;
  startTime: string;
  endTime: string;
  onChange: (startTime: string, endTime: string) => void;
}
const CareTimeItem = observer(
  ({ type, startTime, endTime, onChange }: CareTimeItemProps) => {
    const topmost = useTopmost();
    const onClickTime = e => {
      const target = e.target;
      const id = topmost.show(
        <TimeRangePopup
          type={type}
          min={getMinimunServiceTime(type)}
          max={getMaximumServiceTime(type)}
          start={startTime || getStartTimePlaceholder(type)}
          end={endTime || getEndTimePlaceholder(type)}
          onSubmit={(start, end) => {
            onChange(start, end);
          }}
          onClose={() => topmost.dismiss(id)}
        />,
      );
    };

    return (
      <HorizontalLayout center>
        <CareTypeText>{service[type]}</CareTypeText>
        <Push />
        <CareTimeInput
          placeholder={getStartTimePlaceholder(type)}
          value={startTime}
          onClick={onClickTime}
        />
        <Space width={17} />
        <CareTypeText>~</CareTypeText>
        <Space width={17} />
        <CareTimeInput
          placeholder={getEndTimePlaceholder(type)}
          value={endTime}
          onClick={onClickTime}
        />
      </HorizontalLayout>
    );
  },
);

const CareTypeText = styled.div`
  color: #484848;

  font-size: 16px;
  font-weight: 500;

  letter-spacing: -0.16px;
`;
const CareTimeInput = styled(TextField).attrs({
  variant: 'outlined',
  style: { width: '105px' },
  InputLabelProps: {
    shrink: true,
  },
  InputProps: {
    readOnly: true,
  },
})`
  input {
    text-align: center;
    user-select: none;
  }
`;

const CareScheduleItemContainer = styled(HorizontalLayout)`
  width: 100%;
  height: 76px;

  box-shadow: 3px 4px 12px 0 #dedede;
  background-color: #fff;
  border-radius: 6px;

  padding: 6px 24px;
`;
const CloseIcon = styled.img.attrs({
  src: require('asset/common/close.svg').default,
})`
  width: 10px;
  height: 10px;

  cursor: pointer;
`;
