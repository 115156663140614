import React, { useState } from 'react';
import styled from 'styled-components';
import { TextField } from '@material-ui/core';
import { DateTime } from 'luxon';
import { useDetectClickOutside } from 'react-detect-click-outside';

import { HorizontalLayout } from 'atom/layout';
import { MultiDatePickerPopup } from 'component/popup';
import { useIsMobile, useTopmost } from 'util/index';
import { MultiDatePicker } from './DatePicker';
import { count2DaysLater, count3DaysLater } from 'page/customer/request';

interface MultiDateInputProps {
  hasIcon?: boolean;
  value: DateTime[];
  onChange: (x: DateTime[]) => void;
}
export const MultiDateInput = ({
  hasIcon = false,
  value,
  onChange,
}: MultiDateInputProps) => {
  const isMobile = useIsMobile();
  const topmost = useTopmost();
  const [show, setShow] = useState(false);
  const ref = useDetectClickOutside({
    onTriggered: e => {
      // @ts-ignore
      if (e.target.tagName !== 'ABBR') {
        setShow(false);
      }
    },
  });

  const onClickOpen = () => {
    if (isMobile) {
      const id = topmost.show(
        <MultiDatePickerPopup
          value={value}
          onChange={v => onChange(v)}
          onClose={() => topmost.dismiss(id)}
        />,
      );
    } else {
      setShow(true);
    }
  };

  return (
    <Container ref={ref}>
      <HorizontalLayout>
        <TextField
          placeholder="YYYY-MM-DD"
          variant="outlined"
          style={{ width: hasIcon ? 'calc(100% - 30px)' : '100%' }}
          InputLabelProps={{
            shrink: true,
          }}
          value={value[0] ? value[value.length - 1].toFormat('yyyy-MM-dd') : ''}
          onClick={onClickOpen}
        />
        {hasIcon && <CalendarIcon onClick={onClickOpen} />}
      </HorizontalLayout>

      {/* {value.length > 0 && value.sort()[0].diffNow('days').days < 0 && (
        <ErrorText>시작일은 오늘 이후여야 합니다.</ErrorText>
      )} */}
      {value.length > 0 &&
        value.sort()[0].diffNow('days').days < count2DaysLater() && (
          <ErrorText>영업일 기준, 최소 2일 이후부터 신청가능해요.</ErrorText>
        )}

      {show && (
        <MultiDatePicker
          value={value}
          onChange={value => {
            onChange(value);
          }}
          onClose={() => setShow(false)}
        />
      )}
    </Container>
  );
};

const Container = styled.div``;
const CalendarIcon = styled.img.attrs({
  src: require('asset/icon/calendar.svg').default,
})`
  width: 20px;
  height: 20p;

  margin-left: 10px;

  cursor: pointer;
`;

const ErrorText = styled.div`
  margin-top: 5px;
  color: red;
`;
